import type { AriaSelectProps } from '@react-types/select';
import type { KeyboardEvent } from '@react-types/shared';
import type { PopoverProps } from 'react-aria-components';
import { mergeRefs } from '@react-aria/utils';
import * as React from 'react';
import { HiddenSelect, useSelect } from 'react-aria';
import { Popover } from 'react-aria-components';
import { useSelectState } from 'react-stately';
import useMeasure from 'react-use-measure';

import type { FromSingleSelectionProps } from '../../common/props';
import type { ControlSize } from '../../controls/shared/types';
import { type IconName, Icon } from '../../assets/Icon/Icon';
import { useControlSize } from '../../common/control_size';
import { contentStyles } from '../../common/menus';
import { toSingleSelectionProps } from '../../common/props';
import { Button } from '../../controls/Button/Button';
import { ControlGroup } from '../../controls/ControlGroup/ControlGroup';
import { iconColor, labelColor, transitions } from '../../controls/shared/styles';
import { darkThemeSelector, fontWeights, styled } from '../../stitches.config';
import { BodySansSizes } from '../../text/Body';
import { LargeSansSizes } from '../../text/Large';
import { SmallSansSizes } from '../../text/Small';
import { Text } from '../../text/Text';
import { ComboBoxList } from '../ComboBox/ComboBoxList';
import { SelectContainer, SelectTrigger } from './SelectTrigger';

type SelectProps<T> = FromSingleSelectionProps<AriaSelectProps<T>> & {
  controlSize?: ControlSize;
  disabled?: boolean;
  icon?: IconName;
  invalid?: boolean;
  placeholder?: string;
  width?: string;
  maxWidth?: string | number;
  indeterminate?: boolean;
  minWidth?: string | number;
  showPlaceholderForIndeterminate?: boolean;
  canClearValue?: boolean;
  popoverProps?: PopoverProps;
};

const SelectIcon = styled(Icon, {
  alignSelf: 'center',
  width: '$$iconSize',
  height: '$$iconSize',
  color: iconColor,
});

export const SelectValue = styled(Text, {
  width: '100%',
  fontWeight: fontWeights.bold,
  textAlign: 'left',
  truncate: true,
  transition: transitions.control,

  [darkThemeSelector]: {
    color: labelColor,
  },

  variants: {
    size: {
      'x-small': {
        ...SmallSansSizes,
      },
      small: {
        ...SmallSansSizes,
      },
      medium: {
        ...BodySansSizes,
      },
      large: {
        ...BodySansSizes,
      },
      'x-large': {
        ...LargeSansSizes,
      },
    },
  },
});

const SelectPopover = styled(Popover, contentStyles, {
  padding: '$4',
  overflow: 'auto',
});
SelectPopover.displayName = 'SelectPopover';

function SelectInner<T extends object>(props: SelectProps<T>, ref: React.Ref<HTMLButtonElement>) {
  const renamedProps = toSingleSelectionProps(props);
  const {
    placeholder = 'Select...',
    canClearValue,
    indeterminate,
    showPlaceholderForIndeterminate,
  } = renamedProps;

  const controlSize = useControlSize(props.controlSize, 'medium');

  const triggerRef = React.useRef<HTMLButtonElement>(null);

  const state = useSelectState(renamedProps);

  const [measureRef, { width: inputWidth }] = useMeasure();

  const { triggerProps, valueProps, menuProps } = useSelect(renamedProps, state, triggerRef);

  const onKeyDown = React.useCallback(
    (e: KeyboardEvent) => {
      if (triggerProps.onKeyDown) {
        triggerProps.onKeyDown(e);
      }
      if (props.onKeyDown) {
        props.onKeyDown(e);
      }
    },
    [props, triggerProps],
  );

  const selectTrigger = (
    <SelectTrigger
      {...triggerProps}
      onKeyDown={onKeyDown}
      ref={mergeRefs(ref, triggerRef, measureRef as unknown as React.Ref<HTMLButtonElement>)}
      size={controlSize}
      invalid={props.invalid}
      isDisabled={props.disabled ?? false}
      css={{
        width: props.width ?? '100%',
        maxWidth: props.maxWidth ?? '100%',
        minWidth: props.minWidth ?? '100%',
      }}
    >
      {props.icon && <SelectIcon icon={props.icon} />}
      <SelectValue {...valueProps} size={controlSize}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {indeterminate ? (
          showPlaceholderForIndeterminate ? (
            placeholder
          ) : (
            <>&ndash;&nbsp;</>
          )
        ) : state.selectedItem ? (
          state.selectedItem.rendered
        ) : (
          placeholder
        )}
      </SelectValue>
      <SelectIcon icon="chevrons-vertical" />
    </SelectTrigger>
  );

  return (
    <SelectContainer
      css={{ width: props.width, maxWidth: props.maxWidth, minWidth: props.minWidth }}
    >
      <HiddenSelect state={state} triggerRef={triggerRef} label={props.label} name={props.name} />

      {state.selectedItem && canClearValue ? (
        <ControlGroup size={controlSize}>
          {selectTrigger}
          <Button
            type="button"
            icon="cross"
            variant="secondary"
            arrangement="hidden-label"
            condense
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              state.setSelectedKey('');
            }}
          >
            Clear selection
          </Button>
        </ControlGroup>
      ) : (
        selectTrigger
      )}
      <SelectPopover
        triggerRef={triggerRef}
        isOpen={state.isOpen}
        onOpenChange={state.setOpen}
        placement="bottom start"
        {...props.popoverProps}
        style={{
          minWidth: inputWidth,
          // 400px is an arbitrary width that prevents unusually wide menus.
          // Generally we want the menu to be only as wide as necessary to fit the content,
          // but beyond 400px text should wrap to a new line.
          maxWidth: Math.max(inputWidth, 400),
        }}
      >
        <ComboBoxList {...menuProps} state={state} />
      </SelectPopover>
    </SelectContainer>
  );
}

export const Select = React.forwardRef(SelectInner) as <T extends object>(
  props: SelectProps<T> & { ref?: React.ForwardedRef<HTMLButtonElement> },
) => ReturnType<typeof SelectInner>;

export { Item as SelectItem, Section as SelectSection } from 'react-stately';
export type { SelectProps };
