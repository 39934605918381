import { Button as RACButton } from 'react-aria-components';

import {
  secondaryDisabledStyles,
  secondaryEnabledStyles,
} from '../../controls/Button/ButtonStyles';
import { selectors, transitions } from '../../controls/shared/styles';
import { darkThemeSelector, shadows, styled } from '../../stitches.config';

export const SelectContainer = styled('div', {
  width: 'min-content',
  minWidth: 0,
});
SelectContainer.displayName = 'SelectContainer';

export const SelectTrigger = styled(RACButton, {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$$buttonGap',
  paddingX: '$$paddingX',
  paddingY: '$$paddingY',
  opacity: 1,
  backgroundColor: '$white',
  borderRadius: '$8',
  truncate: true,
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  userSelect: 'none',
  transition: transitions.control,

  [selectors.focus]: {
    outline: 'none',
  },

  [darkThemeSelector]: {
    background: '$gray800',
  },

  variants: {
    size: {
      'x-small': {
        $$buttonGap: '$space$4',
        minWidth: '$20',
        minHeight: '$20',
        $$paddingX: '$space$4',
        $$paddingY: '$space$2',
        $$iconSize: '$space$12',
        borderRadius: '$6',
      },
      small: {
        $$buttonGap: '$space$4',
        minWidth: '$28',
        minHeight: '$24',
        $$paddingX: '$space$6',
        $$paddingY: '$space$4',
        $$iconSize: '$space$12',
        borderRadius: '$8',
      },
      medium: {
        $$buttonGap: '$space$4',
        minWidth: '$32',
        minHeight: '$28',
        $$paddingX: '$space$8',
        $$paddingY: '$space$4',
        $$iconSize: '$space$12',
      },
      large: {
        $$buttonGap: '$space$6',
        minWidth: '$40',
        minHeight: '$36',
        $$paddingX: '$space$12',
        $$paddingY: '$space$8',
        $$iconSize: '$space$12',
        borderRadius: '$10',
      },
      'x-large': {
        $$buttonGap: '$space$8',
        minHeight: '$44',
        $$paddingX: '$space$20',
        $$paddingY: '$space$10',
        $$iconSize: '$space$12',
      },
    },
    isDisabled: {
      true: {
        cursor: 'not-allowed',
        opacity: 0.5,
        ...secondaryDisabledStyles,
      },
      false: {
        ...secondaryEnabledStyles,
      },
    },
    invalid: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      isDisabled: false,
      invalid: true,
      css: {
        boxShadow: shadows.fieldErrorLight,

        '&:hover:not(:focus)': {
          boxShadow: shadows.fieldErrorLight,
        },

        [darkThemeSelector]: {
          boxShadow: shadows.fieldErrorDark,

          '&:hover:not(:focus)': {
            boxShadow: shadows.fieldErrorDark,
          },
        },
      },
    },
  ],
});
SelectTrigger.displayName = 'SelectTrigger';
