import React, { useEffect, useState } from 'react';

import svgContent from './assets/images/noc-eol.svg?raw';

interface EOLOverlayProps {
  svgString?: string;
  animationDuration?: number;
  countdownSeconds?: number;
}

function EOLOverlay({
  svgString = svgContent,
  animationDuration = 1,
  countdownSeconds = 5,
}: EOLOverlayProps) {
  const [countdown, setCountdown] = useState(countdownSeconds);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          window.location.href = 'https://dashboard.meter.com';
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        zIndex: 1000,
      }}
    >
      {/* eslint-disable react/no-danger */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '400px',
          height: '400px',
          animation: `bounce ${animationDuration}s infinite ease-in-out`,
        }}
        dangerouslySetInnerHTML={{ __html: svgString }}
      />

      <div
        style={{
          position: 'absolute',
          top: '10%',
          color: 'white',
          fontSize: '2rem',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        {countdown > 1 ? `Redirecting to Dashboard ${countdown} seconds...` : 'Redirecting...'}
      </div>

      <style>{`
        @keyframes bounce {
          0%, 100% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-20px);
          }
        }
      `}</style>
    </div>
  );
}

export default EOLOverlay;
